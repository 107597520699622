import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/index/navbar"
import Layout from "../components/layout"
import Seo from "../components/SEO/seo"
import { Sidebar } from "../components/ui/sidebar"
import { collapseEvent } from "../helpers/collapseFuntion"
import { ListPriceButton } from "../helpers/ListPriceButton"
import { usePriceListStore } from "../store/PriceListStore"
import "../styles/main.scss"

const Inmunologia = () => {
  const { handleEventCollapse } = collapseEvent()

  //PAGE IMAGE
  const imageUrl = useStaticQuery(
    graphql`
      query {
        siteImg: file(
          relativePath: { eq: "Services/inmunologia_282368782.jpeg" }
        ) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  )

  const { src: imgSource } = imageUrl.siteImg.childImageSharp.fluid //image

  const parametersSEO = {
    description:
      "Esta rama se encarga de estudiar una serie de otros temas como las inmunodeficiencias, las enfermedades autoinmunes, las alergias, entre otras cosas, buscando las posibles causas y soluciones al problema en cuestión",
    meta: [],
    title: "Inmunología laboratorioclinico",
    imgSrc: imgSource,
    currentURL: "https://laboratorioclinico.sv",
    canonicalURL: "https://laboratorioclinico.sv",
    ogTitle: "Inmunología laboratorioclinico",
    keywords: ["que es inmunologia", "que es inmunología"],
  }

  return (
    <>
      <Layout>
        <Seo {...parametersSEO} />
        <div className="banner-services__container">
          <svg
            width="1418"
            height="509"
            viewBox="0 0 1418 509"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1170.03 297.846L1121.05 337.513L1029.23 198.661C1024.68 191.802 1014.82 187.555 1003.41 187.555C994.136 187.555 982.379 190.604 977.179 199.205L897.062 332.178L786.458 14.2989C782.795 3.73804 769.442 0 759.162 0C747.168 0 733.874 4.7542 731.215 15.3877L634.969 399.39L537.008 146.509C533.05 136.275 519.815 132.646 509.653 132.646C499.786 132.646 487.556 135.985 482.888 145.312L397.04 317.806L333.821 225.988C329.212 219.274 319.345 215.137 308.061 215.137C298.962 215.137 290.69 217.895 285.313 222.722L211.046 288.229L205.905 290.333H29.5417C13.2347 290.333 0 298.463 0 308.479C0 318.496 13.2347 326.625 29.5417 326.625H223.571C232.552 326.625 241.06 323.939 246.377 319.149L302.388 270.046L375.652 376.345C380.319 383.131 389.891 387.196 401.235 387.196C413.701 387.196 424.277 382.188 428.118 374.494L506.344 217.278L613.521 494.147C617.539 504.418 630.183 508.083 640.7 508.083C652.694 508.083 665.987 503.329 668.646 492.66L763.475 114.319L862.321 398.483C865.748 408.354 878.687 412.818 889.913 412.818C901.848 412.818 911.538 408.463 915.969 401.168L1004.59 254.223L1088.85 381.607C1093.4 388.466 1103.32 392.712 1114.73 392.712C1123.71 392.712 1131.98 389.99 1137.3 385.236L1205 327.786L1210.14 326.117H1388.93C1405 326.117 1418 318.133 1418 308.262C1418 298.39 1405 290.333 1388.93 290.333H1192.66C1183.73 290.333 1175.29 293.128 1170.03 297.846V297.846Z"
              fill="white"
            />
          </svg>

          <div className="banner-services__container-fade-in"></div>
          <div className="banner-services__container-banner">
            <NavBar />
            <header className="banner-service">
              <div className="banner-service__button">
                <i className="fas fa-circle fa-xs"></i>
                <p>Servicios de Alta Calidad</p>
              </div>
              <h1 className="banner-service__title mt-2 mb-3">Inmunología</h1>
            </header>
          </div>
        </div>
        <div className="content-services__container">
          <StaticImage
            src="../images/content.png"
            className="banner-content__background"
            placeholder="tracedSVG"
            width={1600}
            alt="background"
          ></StaticImage>
          <section className="content-services">
            <Sidebar />
            <div className="content-services__container">
              <div className="content-services__container-images">
                <StaticImage
                  src="../images/Services/inmunologia_282368782.jpeg"
                  alt="inmunologia_img"
                  placeholder="none"
                  layout="constrained"
                />
              </div>
              <div className="content-services__container-section1 mt-2">
                <h2 className="mb-1">¿Qué es la Inmunologia?</h2>
                <p>
                  Es el estudio del sistema inmunológico y es una rama muy
                  importante de las ciencias médicas y biológicas. El sistema
                  inmunológico nos protege de infecciones a través de varias
                  líneas de defensa. Si el sistema inmunitario no funciona como
                  debería, puede provocar enfermedades, como autoinmunidad,
                  alergia y cáncer. Ahora también está quedando claro que las
                  respuestas inmunitarias contribuyen al desarrollo de muchos
                  trastornos comunes que tradicionalmente no se consideran
                  inmunológicos, incluidas las enfermedades metabólicas,
                  cardiovasculares y neurodegenerativas como el Alzheimer.{" "}
                </p>
              </div>
              <div className="content-services__container-section2 mt-2">
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Tipeo sanguineo
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Antigenos febriles
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Latex RA
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Prueba de embarazo en sangre
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    VIH
                  </li>
                </ul>
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    R.P.R
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Proteina C reactiva
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Anticuerpos antinucleares ANA
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Anticuerpos toxoplasma IGM
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Anticuerpos toxoplasma IGG
                  </li>
                </ul>
              </div>
              <div className="content-services__container-section3">
                <h2 className="mb-1 mt-2">¿Qué es el sistema inmunitario?</h2>
                <p>
                  Es un sistema complejo de estructuras y procesos que ha
                  evolucionado para protegernos de las enfermedades. Los
                  componentes moleculares y celulares forman el sistema
                  inmunológico. La función de estos componentes se divide en
                  mecanismos inespecíficos, los que son innatos a un organismo,
                  y respuestas de respuesta, que son adaptativas a patógenos
                  específicos. La inmunología fundamental o clásica implica el
                  estudio de los componentes que componen el sistema inmunitario
                  innato y adaptativo.
                </p>
              </div>
              <ul className="content-services__container-section4 mt-2 mb-3">
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Qué enfermedades trata la Inmunología?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    La inmunología trata los problemas de salud provocados por
                    el sistema inmunitario. También conocidos como alergólogos,
                    los inmunólogos son médicos que diagnostican, tratan y
                    trabajan para prevenir los trastornos del sistema
                    inmunitario.
                  </p>
                  <br />
                  <p>
                    Puede acudir a un inmunólogo si tiene alergias alimentarias
                    o estacionales, fiebre del heno, eczema o una enfermedad
                    autoinmune. Cuando su sistema inmunitario no funciona tan
                    bien como debería, su cuerpo no tiene suficientes defensas
                    contra las infecciones. Eso puede aumentar el riesgo de
                    desarrollar cáncer o enfermedades autoinmunes, que pueden
                    poner a tu sistema inmunitario en una situación de
                    sobrecarga.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Qué significa las siglas V.D.R.L?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Vienen del inglés "Venereal Disease Research Laboratory" y
                    significa: Laboratorio de Investigación de Enfermedades
                    Venéreas. es una prueba de detección de la sífilis. Con ella
                    se miden las proteínas, también llamadas anticuerpos, que el
                    cuerpo humano puede producir si ha estado en contacto con la
                    bacteria que causa la sífilis.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Cuál es el examen requerido para la Licencia de conducir?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Tipeo de sangre. Este es un examen para determinar el grupo
                    sanguíneo al cual usted pertence. El objetivo de determinar
                    el grupo sanguíneo es para que pueda donar su sangre o
                    recibir una transfusión de sangre sin temor a tener efectos
                    secundarios adversos (en caso de accidente, hablando acerca
                    de la licencia de conducir).
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <ListPriceButton />
        <Footer />
      </Layout>
    </>
  )
}
export default Inmunologia
